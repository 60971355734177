import React from "react";

import { Flex, List, ListItem, Text } from "Atoms";
import { Drawer, DrawerCloseButton, DrawerContent } from "Organisms";
import { IDrawerComponentContext } from "Providers/drawerProvider";
import { RefBundleProduct, RefProduct, Allergen } from "Types";
import { useLanguage } from "LanguageProvider";

type Props = {
    product: RefProduct | RefBundleProduct;
};

export const PosProductInfoDrawer: React.FC<IDrawerComponentContext<Props>> = ({
    props: { product },
    onCloseDrawer
}) => {
    const { translate } = useLanguage();

    const hasAllergens = product.allergens && !!product.allergens.length;
    const hasContents = !!product.contents;
    const hasNoInfo = !product.description && !hasContents && !hasAllergens;

    return (
        <Drawer open={true} onClose={onCloseDrawer} from={"right"} size={"md"} closeOnDimmerClick={true}>
            <DrawerContent
                p={8}
                boxShadow="-5px 0 10px rgba(0, 0, 0, 0.2)"
                bg="gray.50"
                userSelect={"none"}
                overflowY={"auto"}
            >
                <DrawerCloseButton top="15px" />
                <Text as="h2" mb={4} fontWeight={"600"}>
                    {product.name}
                </Text>
                {hasNoInfo && <Text color="gray.500">{translate("noAdditionalInfo")}</Text>}
                {!!product.description && <Text whiteSpace={"break-spaces"}>{product.description}</Text>}
                {hasContents && (
                    <Flex direction={"column"} mt={4} h="auto">
                        <Text as="h3" mb={4}>
                            {translate("productContents")}
                        </Text>
                        <Text whiteSpace={"break-spaces"}>{product.contents}</Text>
                    </Flex>
                )}
                {hasAllergens && (
                    <Flex direction={"column"} mt={4} h="auto">
                        <Text as="h3" mb={4}>
                            {translate("allergens")}
                        </Text>
                        <List stretch={0} listStyle="disc" pl={4}>
                            {product.allergens.map((allergen: Allergen, idx: number) => {
                                return (
                                    <ListItem key={idx} fontSize="md">
                                        {allergen.name}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Flex>
                )}
            </DrawerContent>
        </Drawer>
    );
};
